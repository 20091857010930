<template>
	<div id="div">
		<img class="img_bg" src="../../asset/vip/vipB11.png" alt="img_bg" />
		<img class="img_bg" src="../../asset/vip/vipB12.png" alt="img_bg" />
		<img class="img_bg" src="../../asset/vip/vipB13.png" alt="img_bg" />
		<img class="img_bg" src="../../asset/vip/vipB14.png" alt="img_bg" />
    <div class="hao"></div>
		<div class="btn_vi">
			<!-- @click="onClick(3)" wexinPay-->
			<div class="btn01" @click="onClick(80)">
				<div class="he">单词训练营·¥6
				</div>
				<div class="text">8大词书畅学</div>
			</div>
			<div class="btn02" @click="onClick(77)">
				<div class="he">英语刷题计划 ¥18
				</div>
				<div class="text">单词＋真题＋图书</div>
			</div>
		</div>
	</div>
</template>

<script>
	import https from '../../https.js'
	export default {
		components: {},
		data() {
			return {
				animate: false,
				orderResult: '',
				result: "",
				url:"",
				
			}
		},
		mounted: function() {
		},
		created: function() {
		},
		methods: {
			onClick(e) {
				let u = navigator.userAgent;
				let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1;
				if (isAndroid) {
					window.android.purchase(e)
				} else {
					window.webkit.messageHandlers.purchase.postMessage(e)
				}
			},
			parseUrl(url) {
				var result = {};
				var queryArr = url.split("&");
				queryArr.forEach(function(item) {
					var obj = {};
					var value = item.split("=")[1];
					var key = item.split("=")[0];
					result[key] = value;
				});
				return result;
			}
		}
	}
</script>

<style scoped="scoped">
	div,
	ul,
	li,
	span,
	img {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		display: block;
	}
  #div{
      background-color: #1B1C46;
  }
  .hao{height: 140px;}
	.btn_vi {
		display: flex;
		text-align: center;
		align-items: flex-end;
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
    /* transition:all 0.5s linear; */
	}

	.btn_vi>div {
		width: 50%;
		letter-spacing: 2px;
	}

	.btn01 {
		background-image: url(../../asset/vip/btn01.png);
		height: 110px;
		background-size: 100% 100%;
		padding-top: 22px;
		color: #fff;
	}

	.btn01 .he {
		font-size: 28px;
		font-weight: bold;
		line-height: 28px;
		letter-spacing: 1px;
	}

	.btn01 .text {
		font-size: 22px;
		margin-top: 17px;
		line-height: 22px;

	}

	.btn02 {
		background-image: url(../../asset/vip/btn02.png);
		height: 140px;
		background-size: 100% 100%;
		padding-top: 29px;
		color: #fff;
    
	}
  

	.btn02 .he {
		font-size: 30px;
		font-weight: bold;
		line-height: 30px;
		color: #261C00;

	}

	.btn02 .text {
		font-size: 26px;
		margin-top: 17px;
		line-height: 22px;
		padding: 6px 20px;
		background-color: #FF510D;
		display: inline-block;
		border-radius: 0 15px 0 15px;
	}

	.img_bg {
		width: 100%;
	}

	.marquee_box {
		display: block;
		position: relative;
		width: 100%;
		height: 360px;
		overflow: hidden;
		margin: 0 auto;
	}

	.marquee_list {
		display: flex;
		align-items: center;
		width: 800px;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
	}

	.marquee_top {
		transition: all 2s linear;
		margin-left: -188px;
	}

	.marquee_list .li {
		/* height: 230px; */
		width: 140px;
		line-height: 30px;
		font-size: 14px;
		color: #fff;
		margin: 0 24px;
	}

	.li_img {
		position: relative;
	}

	.li_name {
		position: absolute;
		width: 100%;
		top: 42px;
		text-align: center;
		font-size: 22px;
		line-height: 22px;
		font-weight: bold;
	}

	.li_text {
		position: absolute;
		width: 100%;
		top: 91.5px;
		text-align: center;
		font-size: 20px;
		line-height: 20px;
	}

	.li_n {
		margin-top: 17px;
		text-align: center;
		font-size: 22px;
		line-height: 22px;
		font-weight: bold;
	}

	.li_t {
		margin-top: 11px;
		text-align: center;
		font-size: 20px;
		line-height: 20px;
	}

	.marquee_list .li img {
		height: 230px;
		width: 140px;
		display: block;
		height: auto;
	}
</style>
